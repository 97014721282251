
import { Vue, Component } from 'vue-property-decorator'
import moment from 'moment'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import DateSelect from '@/components/DateSelect.vue'

@Component({ components: { ShopAutocomplete, DateSelect } })
export default class OverflowKPIPage extends Vue {
  dateFrom: null | string = null
  dateTo: null | string = null

  shop: null | string = null

  downloadProgressText = ''
  isLoading = false
  abort = false

  async fetchData() {
    this.isLoading = true
    this.abort = false
    let date = moment(this.dateFrom)
    const end = moment(this.dateTo)
    const shop = this.shop
    let data: any[] = []
    const api = this.$api.getOverflow
    try {
      while (!this.abort && date.isSameOrBefore(end)) {
        const dateStr = date.format('YYYY-MM-DD')
        const results = await api(dateStr, shop)
        console.log(dateStr)

        this.downloadProgressText = `${dateStr}のデータをダウンロード中`
        data = data.concat(results)
        date.add(1, 'day')
      }
      this.download(
        'overflow.csv',
        ['店コード', '店', '日付', '時間', '☆', '予約', 'スタッフ'],
        data.map(this.transform)
      )
      this.downloadProgressText = ''
    } catch (err) {
      console.error(err)
      this.downloadProgressText = 'ダウンロード失敗'
    }
    this.isLoading = false
  }

  exportCSV() {
    this.fetchData()
  }

  transform({
    date,
    shopCode,
    shopName,
    time,
    overflowCount,
    reserveCount,
    staffCount,
  }: any) {
    return [
      shopCode,
      shopName,
      date,
      time,
      overflowCount,
      reserveCount,
      staffCount,
    ]
  }

  download(filename: string, headers: string[], rows: any[]) {
    rows = rows.map(row => row.join(','))
    const headerTxt = headers.join(',') + '\r\n'
    let content = rows.join('\r\n')
    let bom = new Uint8Array([0xef, 0xbb, 0xbf])
    let blob = new Blob([bom, headerTxt, content], { type: 'text/csv' })

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      let a = document.createElement('a')
      a.download = filename
      // a.target = '_blank'
      a.href = window.URL.createObjectURL(blob)

      // firefox対策． chromeの場合は append/remove をしなくていいらしい
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
